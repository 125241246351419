<template>
  <v-main id="pages" :class="$vuetify.theme.dark ? undefined : 'grey lighten-3'">
    <router-view />
  </v-main>
</template>

<script>
export default {
  name: "PagesCoreView",

  data: () => ({}),

  computed: {}
};
</script>

<style lang="scss">
</style>
